<template>
  <div class="car">
      <div class="car-item" v-for="(item, idx) in carData" :key="idx">
        <div class="car-item-content">{{item.plateNumber}}</div>
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" :style="{'width': (item.aiPerHundredKm/carData[0].aiPerHundredKm) * 100 + '%'}">
          </div>
        </div>
        <div class="car-item-contentr">{{item.aiPerHundredKm}}</div>
      </div>
  </div>
</template>

<script>
  export default {
    props: {
      carData: {
        default: function () {
          return []
        },
        type: Array
      }
    },
    data () {
      return {
      }
    },
    methods: {

    },
    mounted () {

    }
  }
</script>

<style lang='sass' scoped>
.car
  width: 556px
  height: 361px
  padding-top: 61px
  margin: 0 auto
  &-item
    display: inline-block
    position: relative
    float: left
    width: 100%
    height: 24
    margin-left: 82px
    margin-top: 20px
    max-width: 556px
    vertical-align: middle
    line-height: 24px
    &-content
      width: 80px
      padding-right: 10px
      display: inline-block
      vertical-align: middle
      color: #262626
      font-size: 14px
    &-contentr
      width: 45px
      padding-left: 10px
      display: inline-block
      vertical-align: middle
      color: #262626
      font-size: 14px
.progress
  width: 280px
  height: 24px
  display: inline-block
  vertical-align: middle
  overflow: hidden
  background: rgba(255,226,181,0.2)
  border-radius: 0px 12px 12px 0px
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1)
          box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1)
.progress-bar
  float: left
  width: 0
  height: 100%
  font-size: 12px
  line-height: 24px
  color: #fff
  text-align: center
  border-radius: 0px 12px 12px 0px
  background: linear-gradient(90deg, rgba(250,84,28,1), rgba(255,231,186,1))
  transition: width .6s ease
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15)
          box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15)
  -webkit-transition: width .6s ease
       -o-transition: width .6s ease
          transition: width .6s ease
</style>
